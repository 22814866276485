import React from 'react';
import { Chart } from 'react-chartjs-2';
import { FormattedMessage, useIntl } from 'react-intl';

import { colors, hexColorToRGB } from 'stylesheet';
import Title from 'components/Title';
import { PrintableTitle } from 'pages/PrintableView/PrintableView.style';

interface Props {
  scoreHistory: { label: string; score: number }[];
  totalInTargetOrDone: number;
  nbOfScoreHistoryToDisplay?: number;
  isPrintView?: boolean;
}

const ScoreEvolutionChart: React.FC<Props> = ({
  totalInTargetOrDone,
  scoreHistory,
  nbOfScoreHistoryToDisplay = 5,
  isPrintView = false,
}) => {
  const intl = useIntl();
  const scores = scoreHistory.slice(-nbOfScoreHistoryToDisplay);

  return (
    <>
      {isPrintView ? (
        <PrintableTitle>
          <FormattedMessage id="project.summary-tab.charts.evolution" />
        </PrintableTitle>
      ) : (
        <Title>
          <FormattedMessage id="project.summary-tab.charts.evolution" />
        </Title>
      )}
      <Chart
        type="bar"
        data={{
          labels: scores.map(({ label }) => label),
          datasets: [
            {
              label: intl.formatMessage({
                id: 'project.summary-tab.charts.target',
              }),
              borderColor: `rgb(${hexColorToRGB(colors.purple)})`,
              backgroundColor: `rgba(${hexColorToRGB(colors.purple)}, 0.5)`,
              type: 'line' as const,
              fill: false,
              data: Array(5).fill(totalInTargetOrDone),
            },
            {
              label: intl.formatMessage({
                id: 'project.summary-tab.charts.score',
              }),
              borderColor: `rgb(${hexColorToRGB(colors.pink)})`,
              backgroundColor: `rgba(${hexColorToRGB(colors.pink)}, 0.5)`,
              type: 'bar' as const,
              borderWidth: 2,
              data: scores.map(({ score }) => score),
            },
          ],
        }}
        options={{
          scales: {
            y: {
              suggestedMin: 0,
              suggestedMax: 100,
            },
          },
        }}
      />
    </>
  );
};

export default ScoreEvolutionChart;
