import React from 'react';

import { ProjectVersion } from 'clients/projectVersions/type';

import ComplianceRunChart from './components/ComplianceRunChart';
import ScoreByCategory from './components/ScoreByCategoryChart';
import ScoreEvolutionChart from './components/ScoreEvolutionChart/ScoreEvolutionChart';
import { Container, PrintableContainer } from './Charts.style';

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
);

interface Props {
  categories: ProjectVersion['categories'];
  score: ProjectVersion['score'];
  scoreHistory: ProjectVersion['scoreHistory'];
  isPrintView?: boolean;
}

const Charts: React.FC<Props> = ({
  categories,
  score: { runScore, runTotal, totalInTargetOrDone, runTotalInTargetOrDone },
  scoreHistory,
  isPrintView = false,
}) => {
  const children = (
    <>
      <ScoreEvolutionChart
        totalInTargetOrDone={totalInTargetOrDone}
        scoreHistory={scoreHistory}
        isPrintView={isPrintView}
      />
      <ScoreByCategory categories={categories} isPrintView={isPrintView} />
      <ComplianceRunChart
        runScore={runScore}
        runTotal={runTotal}
        runTotalInTargetOrDone={runTotalInTargetOrDone}
        isPrintView={isPrintView}
      />
    </>
  );
  return isPrintView ? (
    <PrintableContainer className="break-before break-after">
      {children}
    </PrintableContainer>
  ) : (
    <Container>{children}</Container>
  );
};

export default Charts;
