import styled from 'styled-components';
import { colors, font } from 'stylesheet';

export const PrintableTitle = styled.h2`
  color: ${colors.purple};
  ${font.size18};
`;

export const PrintableMainTitle = styled.h2`
  color: ${colors.purple};
  ${font.size24};
`;
