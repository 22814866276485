import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getSpacing } from 'stylesheet';
import styled from 'styled-components';

import { fetchProjectVersion } from 'clients/projectVersions/client';
import { reactQueryKeys } from 'clients/reactQueryConfig';
import { useLocale } from 'providers/LocaleProvider/LocaleProvider';
import { EnrichedProject, ProjectVersionMetadata } from 'clients/projects/type';

import CategoryTab from 'pages/ProjectPage/components/ProjectVersionWithPicker/components/ProjectVersionPage/components/CategoryTab';
import SummaryTab from './components/SummaryTab';

interface Props {
  projectVersionMetadata: ProjectVersionMetadata;
  project: EnrichedProject;
}

const PrintableContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: ${getSpacing(2)};
  grid-row-gap: ${getSpacing(6)};
`;

const ProjectVersionPage: React.FC<Props> = ({
  projectVersionMetadata,
  project,
}) => {
  const { locale } = useLocale();

  const projectVersionQuery = useQuery({
    queryKey: reactQueryKeys.projectVersion(projectVersionMetadata.id, locale),
    queryFn: () => fetchProjectVersion(projectVersionMetadata.id, locale),
  });

  if (!projectVersionQuery.isSuccess) {
    return <></>;
  }

  const projectVersion = projectVersionQuery.data;

  return (
    <PrintableContainer>
      <SummaryTab projectVersion={projectVersion} />
      {projectVersion.categories.map((category, index) => (
        <CategoryTab
          key={index}
          projectVersionId={projectVersion.id}
          category={category}
          isPrintView={true}
          project={project}
        />
      ))}
    </PrintableContainer>
  );
};

export default ProjectVersionPage;
