import styled, { css } from 'styled-components';
import { getSpacing, onLargeScreen, onSmallScreen } from 'stylesheet';

export const Container = styled.div`
  display: grid;

  ${onLargeScreen(css`
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ${getSpacing(12)};
  `)}

  ${onSmallScreen(css`
    grid-template-columns: 1fr;
    grid-row-gap: ${getSpacing(4)};
  `)}
`;

export const CategoriesContainer = styled.div`
  display: grid;

  grid-template-columns: 1fr;
  grid-row-gap: ${getSpacing(3)};
`;

export const ScoreAndChartContainer = styled.div`
  display: grid;

  grid-template-columns: 1fr;
  grid-row-gap: ${getSpacing(3)};
  height: fit-content;
`;

/* ### For Printable View ### */

export const PrintableContainer = styled.div`
  display: grid;
  grid-row-gap: ${getSpacing(4)};
  justify-items: center;
`;

export const PrintableScoreContainer = styled.div`
  min-width: 640px;
`;

export const PrintableCategoriesContainer = styled.div`
  min-width: 640px;
`;

export const PrintableScoreAndChartContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${getSpacing(4)};
`;

export default Container;
