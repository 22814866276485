import { NavigateFunction } from 'react-router';

/*
Same trick as with the auth0 addGetAccessTokenSilently function.

Here I store the navigate function to be able to redirect to the Error page it in the client onError function.

This way I don't have to transform all my API calls into hooks.
*/
let navigate: NavigateFunction = () => {
  console.log(
    'You tried to use the navigate function but BrowserRouter has not rendered yet',
  );
  return;
};

export const getNavigationFunction = () => {
  return navigate;
};

export const addNavigateFunction = (
  navigateFunction: NavigateFunction,
): void => {
  navigate = navigateFunction;
};
