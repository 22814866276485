import styled from 'styled-components';
import { colors, font, getSpacing } from 'stylesheet';
import { ReactComponent as InformationCircle } from 'assets/information-circle.svg';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${getSpacing(2)};
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns:
    ${getSpacing(36)} repeat(4, ${getSpacing(8)})
    ${getSpacing(48)} auto;
`;

export const TextHeaderCell = styled('div')<{ isPrintView?: boolean }>`
  padding: ${getSpacing(1)} ${getSpacing(2)};
  border-bottom: 1px solid ${colors.gray0};
  display: flex;
  justify-content: flex-start;
  color: ${colors.purple};
  ${font.size16};
`;

export const HeaderCell = styled('div')<{ isPrintView?: boolean }>`
  padding: ${getSpacing(1)};
  border-bottom: 1px solid ${colors.gray0};
  display: flex;
  justify-content: center;
  color: ${colors.purple};
  ${font.size16};
`;

export const TextCell = styled('div')<{ isPrintView?: boolean }>`
  border-bottom: 1px solid ${colors.gray_1};
  display: flex;
  align-items: start;
  ${({ isPrintView }) => (isPrintView ? font.size12 : font.size14)};
  padding: ${getSpacing(1)};
  box-sizing: border-box;
  text-align: left;
`;

export const NumberCell = styled('div')<{ isPrintView?: boolean }>`
  border-bottom: 1px solid ${colors.gray_1};
  display: flex;
  align-items: center;
  ${({ isPrintView }) => (isPrintView ? font.size12 : font.size14)};
  padding: ${getSpacing(1)};
  box-sizing: border-box;
  justify-content: center;
`;

export const InformationIcon = styled(InformationCircle)`
  height: ${getSpacing(2.5)};
  color: ${colors.purple};
`;

export const TooltipContainer = styled.div`
  position: relative;
`;

export const Tooltip = styled.div`
  visibility: hidden;
  position: absolute;
  bottom: 120%;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${colors.black};
  color: ${colors.white};
  padding: ${getSpacing(1)} ${getSpacing(2)};
  width: 300px;
`;

export const TooltipWrapper = styled(TooltipContainer)`
  &:hover ${Tooltip} {
    visibility: visible;
  }
`;

/* ### For Printable View ### */

export const PrintableGrid = styled.div`
  display: grid;
  grid-template-columns: 20% repeat(4, 6%) 30% auto;
`;
