import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FormattedMessage } from 'react-intl';

import Button from 'components/Button/Button';

import { Container, Title, Logo, TitleText } from './Login.style';

const Login: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <Container>
      <Title>
        <Logo />
        <TitleText>
          <FormattedMessage id="title" />
        </TitleText>
      </Title>
      <Button id="button-login" onClick={() => loginWithRedirect()}>
        <FormattedMessage id="login" />
      </Button>
    </Container>
  );
};

export default Login;
