import { css, FlattenSimpleInterpolation } from 'styled-components';

const SPACING_UNIT = 8;
const MEASUREMENT_UNIT = 'px';

const getPixels = (multiplier: number): number => multiplier * SPACING_UNIT;

export const getSpacing = (multiplier: number): string =>
  `${getPixels(multiplier)}${MEASUREMENT_UNIT}`;

export const colors = {
  black: '#2a2a2a',
  white: 'white',
  transparent: 'transparent',
  purple: '#7236e2',
  purple_1: '#c7bae3',
  red: '#ff0062',
  red_1: '#ffd6e9',
  red_2: '#fff2f8',
  gray2: '#7c7c7c',
  gray1: '#BEBEBE',
  gray0: '#dedede',
  gray_1: '#eeeeee',
  gray_2: '#f8f8f8',
  pink: '#df73ff',
  green: '#00b894',
};

export const font = {
  size12: css`
    font-size: 12px;
    line-height: 16px;
  `,
  size14: css`
    font-size: 14px;
    line-height: 20px;
  `,
  size16: css`
    font-size: 16px;
    line-height: 24px;
  `,
  size18: css`
    font-size: 18px;
    line-height: 26px;
  `,
  size20: css`
    font-size: 20px;
    line-height: 28px;
  `,
  size24: css`
    font-size: 24px;
    line-height: 28px;
  `,
};

const mobileBreakpoint = 768;

export const onSmallScreen = (args: FlattenSimpleInterpolation) => css`
  @media (max-width: ${mobileBreakpoint - 1}px) {
    ${args}
  }
`;

export const onLargeScreen = (args: FlattenSimpleInterpolation) => css`
  @media (min-width: ${mobileBreakpoint}px) {
    ${args}
  }
`;

export const outline = (
  offset = 4,
  color = colors.purple_1,
): FlattenSimpleInterpolation => css`
  &:focus {
    outline: 3px solid ${color};
    outline-offset: ${offset}px;
  }
`;

export const hexColorToRGB = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `${r}, ${g}, ${b}`;
};
