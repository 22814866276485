import styled from 'styled-components';
import { colors, font, getSpacing } from 'stylesheet';

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  border-bottom: 1px solid ${colors.gray_1};
`;

export const TextArea = styled.textarea`
  flex: 1;
  padding: ${getSpacing(1)};
  box-sizing: border-box;
  border: 3px solid ${colors.transparent};
  ${font.size14};
  white-space: pre-wrap;
  &:focus {
    border: 3px solid ${colors.purple_1};
    border-radius: ${getSpacing(1)};
  }
`;

export const InnerContainer = styled('div')<{ isPrintView?: boolean }>`
  flex: 1;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  ${({ isPrintView }) => (isPrintView ? font.size12 : font.size14)};
`;
