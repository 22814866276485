import React from 'react';

import { Category } from 'clients/projectVersions/type';
import { EnrichedProject } from 'clients/projects/type';

import { Container, PrintableContainer } from './CategoryTab.style';
import SubcategoryDetails from './components/SubcategoryDetails/SubcategoryDetails';
import { PrintableMainTitle } from 'pages/PrintableView/PrintableView.style';

interface Props {
  category: Category;
  projectVersionId: string;
  project: EnrichedProject;
  isPrintView?: boolean;
}

const CategoryTab: React.FC<Props> = ({
  category,
  projectVersionId,
  project,
  isPrintView = false,
}) => {
  const children = category.subcategories.map((subcategory) => (
    <SubcategoryDetails
      key={subcategory.id}
      subcategory={subcategory}
      projectVersionId={projectVersionId}
      project={project}
      isPrintView={isPrintView}
    />
  ));

  return isPrintView ? (
    <PrintableContainer className="break-before">
      <PrintableMainTitle>{category.name}</PrintableMainTitle>
      {children}
    </PrintableContainer>
  ) : (
    <Container>{children}</Container>
  );
};

export default CategoryTab;
