import { Locales } from 'providers/LocaleProvider/LocaleProvider';
import { ProjectVersion, ProjectVersionBackend } from './type';
import { get } from 'clients/client';
import { formatDateToLocaleString } from 'clients/utils';

export async function fetchProjectVersion(
  projectVersionId: string,
  locale: Locales,
): Promise<ProjectVersion> {
  const response = await get<ProjectVersionBackend>(
    `${process.env.REACT_APP_BACKEND_URL}/project-versions/${projectVersionId}`,
    { params: { locale } },
  );

  const projectVersionBackend = response.data;

  return {
    id: projectVersionBackend.id,
    version: projectVersionBackend.version,
    categories: projectVersionBackend.categories.map((category) => ({
      id: category.id,
      key: category.key,
      name: category.name,
      objectif: category.objectif,
      subcategories: category.subcategories.map((subcategory) => ({
        id: subcategory.id,
        name: subcategory.name,
        criterias: subcategory.criterias.map((criteria) => ({
          id: criteria.id,
          description: criteria.description,
          weight: criteria.weight,
          isRunMandatory: criteria.isRunMandatory,
          explanation: criteria.explanation,
          achievement: {
            id: criteria.achievement.id,
            criteriaKey: criteria.achievement.criteriaKey,
            projectVersionId: criteria.achievement.projectVersionId,
            doneDate: criteria.achievement.doneDate
              ? new Date(criteria.achievement.doneDate)
              : null,
            doneDateLabel: criteria.achievement.doneDate
              ? formatDateToLocaleString(
                  new Date(criteria.achievement.doneDate),
                  locale,
                )
              : '',
            target: criteria.achievement.target,
            comment: criteria.achievement.comment,
          },
        })),
        score: {
          score: subcategory.score.score,
          isTargetCompliant: subcategory.score.isTargetCompliant,
          isRunCompliant: subcategory.score.isRunCompliant,
        },
      })),
      score: {
        score: category.score.score,
        total: category.score.total,
        totalInTargetOrDone: category.score.totalInTargetOrDone,
      },
    })),
    score: {
      score: projectVersionBackend.score.score,
      total: projectVersionBackend.score.total,
      targetScore: projectVersionBackend.score.targetScore,
      targetTotal: projectVersionBackend.score.targetTotal,
      runScore: projectVersionBackend.score.runScore,
      runTotal: projectVersionBackend.score.runTotal,
      totalInTargetOrDone: projectVersionBackend.score.totalInTargetOrDone,
      runTotalInTargetOrDone:
        projectVersionBackend.score.runTotalInTargetOrDone,
    },
    scoreHistory: projectVersionBackend.scoreHistory,
  };
}

export async function exportProjectVersion(
  projectVersionId: string,
): Promise<void> {
  const response = await get(
    `${process.env.REACT_APP_BACKEND_URL}/project-versions/${projectVersionId}/export`,
    {
      responseType: 'blob',
    },
  );

  if (!response.data) {
    throw new Error('Cannot retrieve the file. No response data.');
  }

  // Get the filename
  const contentDisposition = response.headers['content-disposition'];

  let fileName = 'rose-export.pdf';
  if (contentDisposition) {
    const match = contentDisposition.match(/filename="(.+)"/);
    if (match && match[1]) {
      fileName = match[1];
    }
  }

  // Convert the Blob response into temp URL
  const url = window.URL.createObjectURL(
    new Blob([response.data] as Blob[], { type: 'application/pdf' }),
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);

  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
}
