import React from 'react';
import { ProjectVersion } from 'clients/projectVersions/type';
import {
  PrintableCategoriesContainer,
  PrintableContainer,
  PrintableScoreAndChartContainer,
  PrintableScoreContainer,
} from 'pages/ProjectPage/components/ProjectVersionWithPicker/components/ProjectVersionPage/components/SummaryTab/SummaryTab.style';
import { FormattedMessage } from 'react-intl';
import CategorySummary from 'pages/ProjectPage/components/ProjectVersionWithPicker/components/ProjectVersionPage/components/SummaryTab/components/CategorySummary';
import LastAchievements from 'pages/ProjectPage/components/ProjectVersionWithPicker/components/ProjectVersionPage/components/SummaryTab/components/LastAchievements';
import Charts from 'pages/ProjectPage/components/ProjectVersionWithPicker/components/ProjectVersionPage/components/SummaryTab/components/Charts';
import Score from 'pages/ProjectPage/components/ProjectVersionWithPicker/components/ProjectVersionPage/components/SummaryTab/components/Score';
import { PrintableMainTitle } from 'pages/PrintableView/PrintableView.style';

interface Props {
  projectVersion: ProjectVersion;
}

const SummaryTab: React.FC<Props> = ({ projectVersion }) => {
  return (
    <PrintableContainer>
      <PrintableMainTitle>
        <FormattedMessage id={`project.tab-summary`}></FormattedMessage>
      </PrintableMainTitle>
      <PrintableScoreContainer>
        <Score projectVersion={projectVersion} isPrintView={true} />
      </PrintableScoreContainer>
      <PrintableCategoriesContainer>
        {projectVersion.categories.map((category) => (
          <CategorySummary
            key={category.name}
            category={category}
            isPrintView={true}
          />
        ))}
      </PrintableCategoriesContainer>
      <PrintableScoreAndChartContainer>
        <LastAchievements projectVersion={projectVersion} isPrintView={true} />
        <Charts
          categories={projectVersion.categories}
          score={projectVersion.score}
          scoreHistory={projectVersion.scoreHistory}
          isPrintView={true}
        />
      </PrintableScoreAndChartContainer>
    </PrintableContainer>
  );
};

export default SummaryTab;
