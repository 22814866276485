import styled from 'styled-components';
import { colors, font, getSpacing } from 'stylesheet';

export const Container = styled.div`
  max-width: ${getSpacing(45)};
  height: fit-content;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-row-gap: ${getSpacing(2)};
`;

export const MainScore = styled.p`
  display: flex;
  justify-content: space-between;
  color: ${colors.purple};
  ${font.size16};
`;

export const OtherScores = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${getSpacing(1)};
`;

export const OtherScore = styled.div`
  display: flex;
  justify-content: space-between;
  ${font.size14};
`;

/* ### For Printable View ### */

export const PrintableContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${getSpacing(2)};
`;
