import React from 'react';
import { Bar } from 'react-chartjs-2';
import { FormattedMessage, useIntl } from 'react-intl';

import { colors, hexColorToRGB } from 'stylesheet';
import { ProjectVersion } from 'clients/projectVersions/type';
import Title from 'components/Title';
import { Container } from './ComplianceRunChart.style';
import { PrintableTitle } from 'pages/PrintableView/PrintableView.style';

interface Props {
  runScore: ProjectVersion['score']['runScore'];
  runTotal: ProjectVersion['score']['runTotal'];
  runTotalInTargetOrDone: ProjectVersion['score']['runTotalInTargetOrDone'];
  isPrintView?: boolean;
}

export const options = {
  maintainAspectRatio: false,
  indexAxis: 'y' as const,
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
  },
  scales: {
    x: {
      suggestedMin: 0,
      suggestedMax: 100,
    },
  },
};

const ComplianceRunChart: React.FC<Props> = ({
  runScore,
  runTotal,
  runTotalInTargetOrDone,
  isPrintView = false,
}) => {
  const intl = useIntl();

  return (
    <Container>
      {isPrintView ? (
        <PrintableTitle>
          <FormattedMessage id="project.summary-tab.charts.compliance-run" />
        </PrintableTitle>
      ) : (
        <Title>
          <FormattedMessage id="project.summary-tab.charts.compliance-run" />
        </Title>
      )}

      <Bar
        data={{
          labels: ['RUN'],
          datasets: [
            {
              label: intl.formatMessage({
                id: 'project.summary-tab.charts.target',
              }),
              borderColor: `rgb(${hexColorToRGB(colors.purple)})`,
              backgroundColor: `rgba(${hexColorToRGB(colors.purple)}, 0.5)`,
              data: [((runTotalInTargetOrDone * 100) / runTotal).toFixed(1)],
            },
            {
              label: intl.formatMessage({
                id: 'project.summary-tab.charts.score',
              }),
              borderColor: `rgb(${hexColorToRGB(colors.pink)})`,
              backgroundColor: `rgba(${hexColorToRGB(colors.pink)}, 0.5)`,
              data: [((runScore * 100) / runTotal).toFixed(1)],
            },
          ],
        }}
        options={options}
      />
    </Container>
  );
};

export default ComplianceRunChart;
