import styled from 'styled-components';
import { colors, font, getSpacing } from 'stylesheet';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FullGrid = styled.div`
  margin-top: ${getSpacing(1)};
  display: grid;
  grid-template-columns: ${getSpacing(24)} auto;
  grid-template-rows: auto auto;
`;

export const ObjectiveContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ObjectiveHeaderCell = styled.div`
  padding: ${getSpacing(1)};
  border-bottom: 1px solid ${colors.gray0};
  height: ${getSpacing(4)};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.purple};
  ${font.size16};
`;

export const ObjectiveCell = styled.div`
  flex-grow: 1;
  padding: ${getSpacing(2)};
  display: flex;
  align-items: center;
  text-align: center;
  ${font.size14};
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: ${getSpacing(24)} repeat(3, ${getSpacing(8)});
`;

export const HeaderCell = styled.div`
  padding: ${getSpacing(1)};
  border-bottom: 1px solid ${colors.gray0};
  height: ${getSpacing(4)};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.purple};
  ${font.size16};
`;

export const Cell = styled.div`
  height: ${getSpacing(4)};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${font.size14};
`;

export const Score = styled.p`
  margin-top: ${getSpacing(3)};
  ${font.size16};
`;

/* ### For Printable View ### */

export const PrintableScore = styled.p`
  margin-top: ${getSpacing(3)};
  margin-bottom: ${getSpacing(2)};
  ${font.size16};
  text-align: right;
`;
