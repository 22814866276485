import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { Subcategory } from 'clients/projectVersions/type';
import Checkbox from 'components/Checkbox';
import Title from 'components/Title';
import { EnrichedProject, ProjectRoles } from 'clients/projects/type';

import {
  Container,
  Grid,
  HeaderCell,
  NumberCell,
  PrintableGrid,
  TextCell,
  TextHeaderCell,
  InformationIcon,
  TooltipWrapper,
  Tooltip,
} from './SubcategoryDetails.style';
import Comment from './components/Comment/Comment';
import DoneCheckbox from './components/DoneCheckbox';
import TargetCheckbox from './components/TargetCheckbox';

interface Props {
  subcategory: Subcategory;
  projectVersionId: string;
  project: EnrichedProject;
  isPrintView?: boolean;
}

const SubcategoryDetails: React.FC<Props> = ({
  subcategory,
  projectVersionId,
  project,
  isPrintView = false,
}) => {
  const children = (
    <>
      <TextHeaderCell isPrintView={isPrintView}>
        <FormattedMessage id="project.category-tab.subcategory-details.criteria" />
      </TextHeaderCell>
      <HeaderCell isPrintView={isPrintView}>
        <FormattedMessage id="project.category-tab.subcategory-details.weight" />
      </HeaderCell>
      <HeaderCell isPrintView={isPrintView}>
        <FormattedMessage id="project.category-tab.subcategory-details.done" />
      </HeaderCell>
      <HeaderCell isPrintView={isPrintView}>
        <FormattedMessage id="project.category-tab.subcategory-details.run" />
      </HeaderCell>
      <HeaderCell isPrintView={isPrintView}>
        <FormattedMessage id="project.category-tab.subcategory-details.target" />
      </HeaderCell>
      <TextHeaderCell isPrintView={isPrintView}>
        <FormattedMessage id="project.category-tab.subcategory-details.explanations" />
      </TextHeaderCell>
      <TextHeaderCell isPrintView={isPrintView}>
        <FormattedMessage id="project.category-tab.subcategory-details.comment" />
        <TooltipWrapper>
          <InformationIcon />
          <Tooltip>
            <FormattedMessage id="project.category-tab.subcategory-details.comment-info" />
          </Tooltip>
        </TooltipWrapper>
      </TextHeaderCell>
      {subcategory.criterias.map((criteria) => (
        <Fragment key={criteria.id}>
          <TextCell isPrintView={isPrintView}>{criteria.description}</TextCell>
          <NumberCell>{Number(criteria.weight)}</NumberCell>
          <NumberCell>
            <DoneCheckbox
              achievement={criteria.achievement}
              projectVersionId={projectVersionId}
              isEditable={
                project.currentUserRole !== ProjectRoles.VIEWER && !isPrintView
              }
            />
          </NumberCell>
          <NumberCell>
            <Checkbox isChecked={criteria.isRunMandatory} />
          </NumberCell>
          <NumberCell>
            <TargetCheckbox
              achievement={criteria.achievement}
              projectVersionId={projectVersionId}
              isEditable={
                project.currentUserRole !== ProjectRoles.VIEWER && !isPrintView
              }
            />
          </NumberCell>
          <TextCell className="no-split" isPrintView={isPrintView}>
            {criteria.explanation}
          </TextCell>
          <Comment
            isPrintView={isPrintView}
            initialComment={criteria.achievement.comment}
            achievementId={criteria.achievement.id}
            projectVersionId={projectVersionId}
            isEditable={
              project.currentUserRole !== ProjectRoles.VIEWER && !isPrintView
            }
          />
        </Fragment>
      ))}
    </>
  );
  return (
    <Container>
      <Title>{subcategory.name}</Title>
      {isPrintView ? (
        <PrintableGrid>{children}</PrintableGrid>
      ) : (
        <Grid>{children}</Grid>
      )}
    </Container>
  );
};

export default SubcategoryDetails;
