import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ProjectVersion } from 'clients/projectVersions/type';
import Title from 'components/Title';

import {
  Container,
  MainScore,
  OtherScores,
  OtherScore,
  PrintableContainer,
} from './Score.style';

interface Props {
  projectVersion: ProjectVersion;
  isPrintView?: boolean;
}

const Score: React.FC<Props> = ({ projectVersion, isPrintView = false }) => {
  const children = (
    <>
      <MainScore>
        <span>
          <FormattedMessage id="project.summary-tab.score.rose" />
        </span>
        <span>
          <FormattedMessage
            id="project.summary-tab.score.score"
            values={{
              score: projectVersion.score.score,
              total: projectVersion.score.total,
            }}
          />
        </span>
      </MainScore>
      <OtherScores>
        <OtherScore>
          <span>
            <FormattedMessage id="project.summary-tab.score.target" />
          </span>
          <span>
            <FormattedMessage
              id="project.summary-tab.score.score"
              values={{
                score: projectVersion.score.targetScore,
                total: projectVersion.score.targetTotal,
              }}
            />
          </span>
        </OtherScore>
        <OtherScore>
          <span>
            <FormattedMessage id="project.summary-tab.score.run" />
          </span>
          <span>
            <FormattedMessage
              id="project.summary-tab.score.score"
              values={{
                score: projectVersion.score.runScore,
                total: projectVersion.score.runTotal,
              }}
            />
          </span>
        </OtherScore>
      </OtherScores>
    </>
  );
  return isPrintView ? (
    <PrintableContainer>{children}</PrintableContainer>
  ) : (
    <Container>
      <Title>
        <FormattedMessage id="project.summary-tab.score.title" />
      </Title>
      {children}
    </Container>
  );
};

export default Score;
